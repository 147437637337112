import React from 'react';
import classNames from 'classnames';

function ResponsivePageSection({ label, alignPosition = 'top', children }) {
  return (
    <div className="container col-xxl-8 px-4">
      <div
        className={classNames(
          'row',
          'flex-lg-row',
          `align-items-${alignPosition}`,
          'g-2',
          'g-md-3',
          'gx-lg-5',
          'py-3'
        )}>
        <div
          className={classNames(
            'col',
            'col-sm-8',
            'col-lg-4',
            'text-right',
            'text-lg-end'
          )}>
          <h4 className={classNames('h3', 'fw-lighter')}>{label}</h4>
        </div>
        <div className={classNames('col-lg-8')}>
          <div
            className={classNames(
              'd-grid',
              'gap-2',
              'd-md-flex',
              'justify-content-md-start',
              'flex-column'
            )}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export { ResponsivePageSection };
