import React from 'react';
import classNames from 'classnames';

import Container from 'react-bootstrap5/Container';
import Row from 'react-bootstrap5/Row';
import Col from 'react-bootstrap5/Col';
import { Parallax, Background } from 'react-parallax';

import { RichTextField } from '../RichTextField';
import { FeatureImage, LazyBlurredImage } from '../Image';
import styles from './HeroBlock.module.scss';

function HeroFeatureImage({ useImage, height, big, sizes = '80vw' }) {
  return (
    <div className={classNames('g-0')}>
      {useImage.type === 'vector' ? (
        <div
          style={{ height: height }}
          className={classNames('d-flex', 'justify-content-center')}>
          <div
            className={classNames(styles.svgWrapper, 'm-2')}
            dangerouslySetInnerHTML={{ __html: useImage.value.meta.data }}
          />
        </div>
      ) : (
        <div
          className={classNames(
            'd-flex',
            'flex-column',
            'justify-content-center',
            'overflow-hidden'
          )}
          style={{ height: big ? '100%' : height }}>
          <div>
            <FeatureImage
              {...useImage.value}
              layout={'responsive'}
              sizes={sizes}
              className={classNames(useImage.value.className)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function HeroBackgroundImage({ useImage, className }) {
  const { alt, src, meta } = useImage.value;

  return useImage.type === 'vector' ? (
    <div
      className={classNames(styles.svgWrapper)}
      // style={{ height: 300, width: '100%' }}
      dangerouslySetInnerHTML={{ __html: useImage.value.meta.data }}
    />
  ) : (
    <LazyBlurredImage
      alt={alt}
      src={src}
      meta={meta}
      layout={'fill'}
      objectFit={'cover'}
      objectPosition={'50% 100%'} // FIXME: 0% or 100% based on +/- strength
      loading={'eager'}
      className={classNames(
        className,
        useImage.value.className,
        styles.ImageBackground
      )}
    />
  );
}

function HeroContent({
  headingText,
  leadText,
  big = false,
  // actions
}) {
  const headingSize = big ? 'display-4 fw-bold' : 'display-5 fw-light';
  const leadSize = big ? 'lead' : 'fs-5';

  return (
    <>
      <h1
        className={classNames(headingSize, 'my-2')}
        style={{ letterSpacing: big ? '-0.04em' : null, lineHeight: 1.1 }}>
        {headingText}
      </h1>
      <div className={classNames(leadSize, 'rich-text', 'mb-n3')}>
        <RichTextField richText={leadText} />
      </div>
      {/* {children ?? (
        <div className={classNames('actions')}>{children}</div>
      )} */}
    </>
  );
}

function ImageBesideHero({
  headingText,
  leadText,
  image,
  imageBefore = false,
  className,
  wrapperClassName,
  big = false,
  // children,
}) {
  const useImage = image ? image[0] : null;
  const useMaxHeight = big ? 400 : 300;
  const useMargin = big ? 'mt-0 mb-4' : 'my-4';

  return (
    <div
      className={classNames(
        className,
        big && 'bg-gradient',
        useMargin,
        'py-0'
      )}>
      <Col className={classNames(wrapperClassName, 'container')}>
        <Row
          className={classNames(
            imageBefore ? 'flex-lg-row' : 'flex-lg-row-reverse',
            'align-items-center',
            big ? 'gy-4' : 'gy-1',
            big ? 'gx-4 gx-lg-5' : 'gx-4'
          )}>
          <Col lg={6}>
            {useImage && (
              <HeroFeatureImage
                useImage={useImage}
                height={useMaxHeight}
                big={big}
              />
            )}
          </Col>
          <Col xs={10} sm={10} lg={6}>
            <HeroContent
              headingText={headingText}
              leadText={leadText}
              big={big}
            />
          </Col>
        </Row>
      </Col>
    </div>
  );
}

function ImageAboveHero({
  headingText,
  leadText,
  image,
  className,
  wrapperClassName,
  big = false,
  // children,
}) {
  const useImage = image ? image[0] : null;
  const useMargin = big ? 'mt-0 mb-4' : 'my-4';
  const useMaxHeight = big ? '350px' : 'clamp(250px, 30vh, 350px)';

  return (
    <div
      className={classNames(
        className,
        'bg-gradient',
        useMargin,
        'py-0',
        'user-select-none'
      )}>
      <Container className={classNames(wrapperClassName)}>
        <Row>
          <Col
            xs={11}
            lg={9}
            xxl={7}
            className={classNames(
              'd-flex',
              'mx-auto',
              'flex-column',
              'align-items-center',
              'text-center',
              'justify-content-center'
            )}>
            <Container className={classNames('g-0')}>
              <Row>
                <Col
                  md={9}
                  lg={8}
                  xxl={7}
                  className={classNames('my-2', 'mx-auto')}
                  style={{
                    minHeight: 100,
                    // maxHeight: useMaxHeight,
                    // minWidth: 100,
                    // maxWidth: '40vw',
                    // width: '100%',
                    // flex: '0 0 auto',
                  }}>
                  {useImage && (
                    <HeroFeatureImage
                      useImage={useImage}
                      height={useMaxHeight}
                      big={big}
                      sizes={'50vw,80vw'}
                    />
                  )}
                </Col>
              </Row>
            </Container>
            <HeroContent
              headingText={headingText}
              leadText={leadText}
              big={big}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const HERO_LAYOUT_STYLE_MAP = {
  image_above: ImageAboveHero,
  image_before: ImageBesideHero,
  image_after: ImageBesideHero,
};

function Hero({ headingText, leadText, image, imageLayout, color, big }) {
  const HeroStrategy = HERO_LAYOUT_STYLE_MAP[imageLayout];
  const isBesideLayout = ['image_before', 'image_after'].includes(imageLayout);
  const verticalPadding = 'py-5';
  const heroProps = {
    headingText,
    leadText,
    image,
    className: classNames(
      imageLayout === 'image_beside' ? 'py-lg-5' : null,
      color.bgColor && `bg-${color.bgColor}`,
      color.textColor && `text-${color.textColor}`
    ),
    wrapperClassName: classNames(
      verticalPadding,
      isBesideLayout ? 'px-4' : null
    ),
    big,
  };

  if (isBesideLayout) {
    heroProps.imageBefore = imageLayout === 'image_before';
  }

  return <HeroStrategy {...heroProps} />;
}

function GhostHero({
  headingText,
  leadText,
  backgroundImage,
  backgroundOpacity = 0.5,
  color,
  big = true,
  // children,
}) {
  const useImage = backgroundImage ? backgroundImage : null;
  const useMargin = big ? 'mt-0 mb-4' : 'my-2';
  const useClassName = classNames(
    color.bgColor && `bg-${color.bgColor}`,
    color.textColor && `text-${color.textColor}`
  );
  const textShadow = color.bgColor
    ? `0px 0px 12px var(--bs-${color.bgColor}),
       0px -1px 2px var(--bs-${color.bgColor}),
       0px 1px 2px var(--bs-${color.bgColor})`
    : null;

  return (
    <div className={classNames(useMargin, 'user-select-none')}>
      <Container
        fluid
        className={classNames(useClassName, 'bg-gradient', 'g-0')}>
        <Parallax strength={400} className={classNames(styles.ImageParallax)}>
          <Background>
            {/* TODO: Correct height formula (strength-based) ? */}
            <div style={{ height: 800, opacity: backgroundOpacity }}>
              {useImage && (
                <HeroBackgroundImage useImage={{ value: useImage }} />
              )}
            </div>
          </Background>
          <Container
            className={classNames(
              big ? 'py-3 py-lg-5' : 'py-1 py-lg-3',
              big && 'my-3 my-lg-5'
            )}>
            <Row>
              <Col
                sm={12}
                lg={10}
                xxl={8}
                className={classNames(
                  'd-flex',
                  'mx-auto',
                  'flex-column',
                  'align-items-center',
                  'text-center',
                  'justify-content-center'
                )}>
                <div
                  style={{ textShadow }}
                  className={classNames(
                    'd-flex',
                    'flex-column',
                    'justify-content-center',
                    'align-items-center',
                    'text-center',
                    'p-5',
                    'my-5'
                  )}>
                  <HeroContent
                    headingText={headingText}
                    leadText={leadText}
                    big={big}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Parallax>
      </Container>
    </div>
  );
}

function HeroBlock({ id, value, big = false }) {
  return (
    <Hero
      headingText={value.headingText}
      leadText={value.leadText}
      image={value.image}
      imageLayout={value.imageLayout}
      color={value.color}
      big={big}
    />
  );
}

function BigHeroBlock({ id, value }) {
  return <HeroBlock id={id} value={value} big={true} />;
}

function GhostHeroBlock({ id, value }) {
  return (
    <GhostHero
      headingText={value.headingText}
      leadText={value.leadText}
      backgroundImage={value.backgroundImage}
      backgroundOpacity={value.backgroundOpacity}
      color={value.color}
      big={true}
    />
  );
}

export { HeroBlock, BigHeroBlock, GhostHeroBlock };
