import React from 'react';
import classNames from 'classnames';

const NotFoundPage = () => {
  return (
    <div
      className={classNames(
        'p-5',
        'text-center',
        'd-flex',
        'flex-column',
        'flex-fill',
        'justify-content-center'
      )}>
      <h2 className={classNames('display-3', 'text-dark')}>
        Error 404:{' '}
        <span className={classNames('fw-normal', 'text-danger')}>
          Not Found
        </span>
      </h2>
      <p>The requested document could not be located</p>
    </div>
  );
};

NotFoundPage.propTypes = {};

NotFoundPage.defaultProps = {};

export default NotFoundPage;
