import dynamic from 'next/dynamic';

import DocsPage from './DocsPage';
import ProductPage from './ProductPage';
import ContentPage from './ContentPage';
import NotFoundPage from './NotFoundPage';
import PlasmicPage from './PlasmicPage';
import SlideShowPage from './SlideShowPage';

const CONTAINER_MAP = {
  // Core pages are pre-loaded for performance
  DocsPage,
  ProductPage,
  ContentPage,
  NotFoundPage,
  PlasmicPage,
  SlideShowPage,

  // These are lazy loaded (for now);
  // FIXME: This is a BAD lazy loading strategy that causes a massive repaint
  // when the lazy-loaded module is first visited (empty DOM) then loads
  // (DOM node for entire page suddenly appears).
  // NotFoundPage: dynamic(() => import('./NotFoundPage')),
  PasswordProtectedPage: dynamic(() => import('./PasswordProtectedPage')),
  PureHtmlPage: dynamic(() => import('./PureHtmlPage')),
  BasePage: dynamic(() => import('./BasePage')),
};

export default CONTAINER_MAP;
