import React from 'react';
import classNames from 'classnames';

import Container from 'react-bootstrap5/Container';

function DividerBlock({ id, value }) {
  return (
    <Container
      className={classNames(value.followContentWidth ? 'gx-3' : 'g-0', 'my-4')}
      fluid={!value.followContentWidth}>
      <hr
        style={{ height: `${value.thickness}px`, opacity: 1.0 }}
        className={classNames(`bg-${value.color}`, 'm-0')}
        key={id}
      />
    </Container>
  );
}

export { DividerBlock };
