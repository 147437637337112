import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const DocsHeading = ({
  children,
  sectionTitle = null,
  className,
  ...other
}) => {
  return (
    <div {...other} className={classNames('docs-heading', className)}>
      <div>
        {sectionTitle !== null && (
          <p className={classNames('opacity-75', 'lh-sm', 'mb-n1', 'mt-1')}>
            {sectionTitle}
          </p>
        )}
        <h1 className={classNames('lh-sm')}>{children}</h1>
      </div>
    </div>
  );
};

DocsHeading.propTypes = {
  children: PropTypes.node.isRequired,
  sectionTitle: PropTypes.string,
  leadParagraph: PropTypes.bool,
};
