import React from 'react';
import NextImage, { ImageLoader, ImageProps } from 'next/image';
import useNextBlurhash from 'use-next-blurhash';

// IMPORTANT: Key CURRENTLY available both server AND build but this should
// be changed for production.
const IMAGE_RENDER_KEY = process.env.IMAGE_RENDER_KEY;
const WAGTAIL_MEDIA_HOST = process.env.WAGTAIL_MEDIA_HOST;
const GENERATE_IMAGE_PREFIX = process.env.WAGTAIL_IMAGE_PREFIX;
const SERVE_IMAGE_PREFIX = process.env.NEXT_PUBLIC_WAGTAIL_IMAGE_PREFIX;

// wagtail operations:
// https://github.com/wagtail/wagtail/blob/main/wagtail/images/wagtail_hooks.py#L131-L145

/**
 * This class conceptually encapsulates consistent image data as expected to
 * arrive from Wagtail. It is used for processing operations into suitable
 * filter string for query to a Wagtail server.
 */
class WagtailImage {
  imageProps: Record<string, unknown>;
  meta: Record<string, unknown>;
  dimensions: Record<string, number>;
  aspectRatio: Record<string, number>;
  quality?: number;

  constructor(imageProps, meta) {
    this.imageProps = imageProps;
    this.meta = meta;

    this.dimensions = this.parseDimensions(this.imageProps);
    this.aspectRatio = this.getAspectRatio(
      this.dimensions.width,
      this.dimensions.height
    );
    // TODO
  }

  parseDimensions(imageProps) {
    return {
      width: parseInt(imageProps.width, 10),
      height: parseInt(imageProps.height, 10),
    };
  }

  parseMeta(meta) {
    return meta;
  }

  getAspectRatio(width: number, height: number) {
    for (let num = height; num > 1; num--) {
      if (width % num == 0 && height % num == 0) {
        width = width / num;
        height = height / num;
      }
    }

    return { width, height };
  }

  applyWidth(width) {
    // TODO
  }

  applyFill(width, height, closeness) {
    //
  }

  buildFilterSpec(operations, requestWidth) {
    // TODO:
    const opStrings: string[] = [];
    let opIdx = 0;

    for (const operation of operations) {
      let opString = operation.renderFilter(this, requestWidth);

      if (opIdx === 0) {
        if (!opString) {
          opString = `width-${requestWidth}`;
        }
      }

      if (opString) {
        opStrings.push(opString);
      }

      opIdx++;
    }

    return opStrings.join('|');
  }
}

class Operation {
  slug: string;
  args: Record<string, unknown>;
  discard = false; // Most (resize) operations
  __name__: string;

  constructor(args) {
    this.args = this.parseArgs(args);
    this.__name__ = Object.getPrototypeOf(this).constructor.name;

    // console.debug(`${this.__name__}.constructor; args:`, this.args);
  }

  parseArgs(args): Record<string, unknown> {
    return { args: args };
  }

  renderArgs(image, requestWidth) {
    return this.args ? this.args.args : '';
  }

  _render(image, requestWidth) {
    const argstr = this.renderArgs(image, requestWidth);

    return argstr ? `${this.slug}-${argstr}` : this.slug;
  }

  renderFilter(image, requestWidth) {
    // console.debug(`${this.__name__}.renderFilter`, this.args, image, requestWidth);

    return this.discard ? null : this._render(image, requestWidth);
  }
}

// *** SIZE operations

// Use original image dimensions (passthrough)
class OriginalSizeOperation extends Operation {
  slug: 'original';
  discard = true;
  // TODO
}

// Fills all available space for xy: `fill-400x400-c[0-100]` => x400, y400
// The aspect ratio of this operation must be preserved (i.e. 1:1) while
// rendering the image
class FillSizeOperation extends Operation {
  slug = 'fill';

  // format: `[width]x[height]`, `c[0-100]`
  parseArgs(args) {
    const [x, y] = args[0].split('x');
    let centering;

    if (args[1]) {
      centering = parseInt(args[1].substr(1), 10);
    }

    return {
      width: parseInt(x, 10),
      height: parseInt(y, 10),
      centering,
    };
  }

  renderArgs(image, requestWidth) {
    const desiredAspect = image.getAspectRatio(
      this.args.width,
      this.args.height
    );
    const desiredSize = {
      width: requestWidth,
      height: Math.round(
        (requestWidth / desiredAspect.width) * desiredAspect.height
      ),
    };
    let useCentering = 0;

    if (this.args.centering) {
      useCentering = this.args.centering;
    }

    // FIXME: Restore centering argument
    // return `${desiredSize.width}x${desiredSize.height}-c${useCentering}`;
    return `${desiredSize.width}x${desiredSize.height}`;
  }
}

// Contrain SHORTEST SIDE to width/height
class MinSizeOperation extends Operation {
  slug = 'min';
  discard = true;

  parseArgs(args) {
    const [x, y] = args.split('x'); // format is `min-000xYYY`

    return { width: parseInt(x, 10), height: parseInt(y, 10) };
  }

  renderArgs(image, requestWidth) {
    return `${this.args.width}x${this.args.height}`;
  }
}

// Contrain LARGEST SIDE to width/height; xxl is OK!
class MaxSizeOperation extends Operation {
  slug = 'max';
  discard = true;

  parseArgs(args) {
    const [x, y] = args.split('x'); // format is `min-000xYYY`

    return { width: parseInt(x, 10), height: parseInt(y, 10) };
  }

  renderArgs(image, requestWidth) {
    return `${this.args.width}x${this.args.height}`;
  }
}

// Constrain WIDTH to dimension
class WidthOperation extends Operation {
  slug = 'width';
  discard = true;

  parseArgs(args) {
    return { width: parseInt(args[0], 10) };
  }

  renderArgs(image, requestWidth) {
    return `${this.args.width}`;
  }
}

// Constrain HEIGHT to dimension
class HeightOperation extends Operation {
  slug = 'height';
  discard = true;

  parseArgs(args) {
    return { height: parseInt(args[0], 10) };
  }

  renderArgs(image, requestWidth) {
    return `${this.args.height}`;
  }
}

// Scale whole image (avoid?)
class ScaleOperation extends Operation {
  slug = 'scale';
  discard = true;

  // TODO
  // parseArgs(args) {
  //   return { height: parseInt(args[0], 10) };
  // }

  // renderArgs() {
  //   return `${this.args.height}`;
  // }
}

// *** COMPRESSION operations

// JPEG compression (avoid?)
class JpegQualityOperation extends Operation {
  slug: 'jpegquality';

  parseArgs(args) {
    return args.map((arg) => parseInt(arg, 10));
  }
}

// WEBP compression
class WebpQualityOperation extends Operation {
  slug: 'webpquality';

  parseArgs(args) {
    return args.map((arg) => parseInt(arg, 10));
  }
}

// *** FORMAT operations

// Convert image type to `webp` or `jpeg`
class FormatOperation extends Operation {
  slug: 'format';
}

// *** COLOR operations

// 3- or 6-digit hexadecimal code to apply (opaque) bgcolor to transparent image
class BgColorOperation extends Operation {
  slug: 'bgcolor';
  // TODO
}

const WAGTAIL_IMAGE_OPERATIONS = {
  original: OriginalSizeOperation,
  fill: FillSizeOperation,
  min: MinSizeOperation,
  max: MaxSizeOperation,
  width: WidthOperation,
  height: HeightOperation,
  scale: ScaleOperation,
  jpegquality: JpegQualityOperation,
  webpquality: WebpQualityOperation,
  format: FormatOperation,
  bgcolor: BgColorOperation,
};

function parseWagtailImageFilter(filterSpec) {
  const operations = [];

  for (const op_spec of filterSpec.split('|')) {
    const op_spec_parts = op_spec.split('-');
    const operation_name = op_spec_parts[0];
    const registered_operations = Object.keys(WAGTAIL_IMAGE_OPERATIONS);

    if (!registered_operations.includes(operation_name)) {
      // throw error
      throw new Error(`Unregistered image operation '${operation_name}'`);
    }

    const OperationClass = WAGTAIL_IMAGE_OPERATIONS[op_spec_parts[0]];
    const operationInst = new OperationClass(op_spec_parts.slice(1));

    operations.push(operationInst);
  }

  return operations;
}

enum ImageLayoutBehavior {
  intrinsic = 'intrinsic',
  fixed = 'fixed',
  responsive = 'responsive',
  fill = 'fill',
}

function simpleLoader({ src, width, quality }) {
  return `${src}?w=${width}`;
}

/**
 * The default next.js loader only includes src, width and quality parameters;
 * imageProps and meta are passed via currying function withWagtailFilterLoader.
 *
 * It executes multiple code-paths for server vs. browser, and responsive
 * type image handling, translating image properties defined by
 */
function wagtailImageFilterLoader({ src, width, quality, imageProps, meta }) {
  const {
    imageId,
    imageGuid,
    filterSpec,
    filterCacheKey,
    filePrefix,
    fileExtension,
  } = meta;

  // FIXME: next/image types; better/more com
  const responsiveLayoutBehaviors = [
    ImageLayoutBehavior.intrinsic, // FIXME: broken support!
    ImageLayoutBehavior.responsive, // should work as expected
    ImageLayoutBehavior.fill, // should work as expected
  ];
  const isResponsive = responsiveLayoutBehaviors.includes(imageProps.layout);

  // TODO: Extract
  let useFilterSpec = filterSpec;

  // NOTE: Responsive images transform
  if (isResponsive) {
    // Construct a model of image and filters for width processing
    const wagtailImage = new WagtailImage(imageProps, meta);
    const operations = parseWagtailImageFilter(meta.filterSpec);

    useFilterSpec = wagtailImage.buildFilterSpec(operations, width);
  }

  // TODO: impl; destination filename may change w/ certain filters
  const useFileExtension = fileExtension;

  // Construct the expected cache filename for the reconstructed filter spec
  const useFilename = [
    filePrefix,
    filterCacheKey,
    useFilterSpec,
    useFileExtension,
  ]
    .filter((val) => !!val) // filterCacheKey is usually empty
    .join('.');

  // IMPORTANT: this stupid trick generates image renditions on demand from
  // the server ONLY. The expected (cache) filename for the rendition is
  // validated and the image is loaded from that static path
  if (IMAGE_RENDER_KEY && GENERATE_IMAGE_PREFIX) {
    const searchParams = new URLSearchParams();

    // FIXME: (re)-create filter_spec hash instead: https://caligatio.github.io/jsSHA/
    searchParams.set('key', IMAGE_RENDER_KEY);
    searchParams.set('image_guid', imageGuid);
    searchParams.set('filter_spec', useFilterSpec);
    if (quality) {
      searchParams.set('quality', quality);
    }

    const loaderUrl = `${useFilename}?${searchParams.toString()}`;
    const generateImageUrl = `${WAGTAIL_MEDIA_HOST}${GENERATE_IMAGE_PREFIX}/${loaderUrl}`;

    // FIXME: Replace this fetch call with a more efficient event (websocket?)
    fetch(generateImageUrl);
  }

  // Note: the width is included as an (ignored) runtime parameter, otherwise
  // next.js complains that you haven't implemented the width value.
  return `${SERVE_IMAGE_PREFIX}/${useFilename}?w=${width}`;
}

/**
 * The next/image concept of a loader only includes width, quality and url. This
 * function curries next's image loader props with more complete image data and
 * metadata for enhanced rendering.
 */
function withWagtailFilterLoader(loader, imageProps, meta) {
  function wrapLoader(loaderProps) {
    loaderProps.imageProps = imageProps;
    loaderProps.meta = meta;
    return loader(loaderProps);
  }

  return wrapLoader;
}

function LazyBlurredImage({ meta, ...imageProps }) {
  const defaultProps: Record<string, unknown> = {
    layout: 'responsive',
    loader: withWagtailFilterLoader(wagtailImageFilterLoader, imageProps, meta),
  };
  // https://github.com/ivansevillaa/use-next-blurhash
  // FIXME: useNextBlurhash impl NOT DETERMINISTIC (?), hydration errors
  const [blurDataURL] = useNextBlurhash(meta.blurhash, 4, 4);
  const lazyProps: Record<string, unknown> = {
    priority: false,
    placeholder: 'blur',
    blurDataURL,
  };
  const finalProps = Object.assign(
    {},
    defaultProps,
    lazyProps,
    imageProps
  ) as ImageProps;

  return <NextImage src={finalProps.src} {...finalProps} />;
}

function FeatureImage({ meta, ...imageProps }) {
  return <LazyBlurredImage meta={meta} priority={true} {...imageProps} />;
}

export { LazyBlurredImage, FeatureImage, simpleLoader };
