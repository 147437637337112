import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  DocsLayout,
  LayoutContainer,
  BlockQuote,
} from '@penfold/react-components';

import { basePageWrap } from '../BasePage';
import { RichTextField } from '../../components/RichTextField';
import { LazyBlurredImage } from '../../components/Image';
import {
  APIRichTextBlock,
  HeroBlock,
  GhostHeroBlock,
  DividerBlock,
} from '../../components/blocks';

const DocsPageBodyBlock = ({ body }) => {
  const rendered = [];

  if (!body) return null;

  for (const block of body) {
    if (!block) {
      console.warn('Cannot render null item');
      rendered.push(
        <div
          key={serialIdx}
          role="alert"
          className={classNames(
            'alert',
            'alert-warning',
            'text-white',
            'my-3'
          )}>
          Cannot render null block at idx: {serialIdx}
        </div>
      );
      continue;
    }

    switch (block?.type) {
      case 'heading': {
        rendered.push(
          <LayoutContainer key={block.id}>
            {/* FIXME: variable heading level */}
            <h2>{block.value}</h2>
          </LayoutContainer>
        );
        break;
      }
      case 'paragraph': {
        rendered.push(
          <APIRichTextBlock key={block.id} id={block.id} value={block.value} />
        );
        break;
      }
      case 'divider': {
        // FIXME: does not implement block contract values at all
        rendered.push(
          <DividerBlock key={block.id} id={block.id} value={block.value} />
        );
        break;
      }
      case 'image': {
        rendered.push(
          <div
            key={block.id}
            className={classNames(
              'bg-dark',
              'bg-gradient',
              'mt-2',
              'mb-4',
              'py-4'
            )}>
            <LayoutContainer>
              <LazyBlurredImage {...block.value} />
            </LayoutContainer>
          </div>
        );
        break;
      }
      case 'blockquote': {
        rendered.push(
          <LayoutContainer key={block.id}>
            <div className={classNames('rich-text')}>
              <BlockQuote>{block.value}</BlockQuote>
            </div>
          </LayoutContainer>
        );
        break;
      }
      case 'media': {
        console.warn('FIXME; media not rendering');
        // let src = extractSrcFromEmbedIframe(block.value);

        // src += '&modestbranding=1&rel=0&fs=1';
        // rendered.push(
        //   <div key={block.id}>
        //     <ResponsiveIFrame src={src} />
        //   </div>
        // );
        break;
      }
    }
  }

  return rendered;
};

const DocsPage = ({ title, sectionTitle, leadParagraph, body }) => {
  return (
    <DocsLayout
      pageTitle={title}
      sectionTitle={sectionTitle}
      leadParagraph={
        leadParagraph ? <RichTextField richText={leadParagraph} /> : null
      }>
      <DocsPageBodyBlock body={body} />
    </DocsLayout>
  );
};

// DocsPage.defaultProps = {
//   title: '',
//   sectionTitle: '',
//   leadParagraph: '',`
//   body: ''
// };

DocsPage.propTypes = {
  title: PropTypes.string.isRequired,
  richText: PropTypes.string,
};

export default basePageWrap(DocsPage);
