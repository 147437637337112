import mdiIconPaths from './IconPathData';

// TODO: Retire this concept; components etc. should import their own path data

const getMaterialIconPath = (iconName, defaultIconName = 'Puzzle') => {
  // Help developers out if they don't capitalize the first letter
  const useIconName = iconName
    ? iconName.charAt(0).toUpperCase() + iconName.substr(1)
    : defaultIconName;

  const defaultIcon = defaultIconName
    ? mdiIconPaths[`mdi${defaultIconName}`] || null
    : null;
  return mdiIconPaths[`mdi${useIconName}`] || defaultIcon || null;
};

export { getMaterialIconPath, mdiIconPaths };
