import BasePage from './BasePage';
import React from 'react';

import { Layout } from '../../components/Layout';

export const basePageWrap = (Component) => (props) => {
  return (
    <BasePage {...props} _class={Component.name} layout={Component.layout}>
      <Component {...props} />
    </BasePage>
  );
};

export default BasePage;
