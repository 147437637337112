import React from 'react';
import classNames from 'classnames';

function BlockQuote({ children }) {
  return (
    <div className={classNames('rich-text')}>
      <blockquote
        className={classNames('position-relative', 'fs-4', 'mb-4', 'mt-3', 'mx-3')}
      >
        <span
          aria-hidden="true"
          className={classNames(
            'position-absolute',
            'display-1',
            'fw-bold',
            'opacity-25',
            'text-info'
          )}
          style={{ top: '1.25rem', left: 0, zIndex: 0, lineHeight: 0 }}
        >
          &ldquo;
        </span>
        {children}
      </blockquote>
    </div>
  );
}

export { BlockQuote };
