import React from 'react';
import classNames from 'classnames';

import { Icon } from '@penfold/react-components';

function IconDefinition({
  iconName,
  iconTitle = false,
  iconColor = null,
  label,
  children,
}) {
  return (
    <div
      className={classNames(
        'd-block',
        'd-lg-inline',
        'text-nowrap',
        'my-1',
        'col-md-6',
        'col-xl-auto'
      )}>
      <dt
        className={classNames(
          'font-weight-normal',
          'd-inline',
          'mr-2',
          'me-2'
        )}>
        <Icon
          name={iconName}
          size={1}
          title={iconTitle}
          className={classNames(
            'mr-2',
            'me-2',
            iconColor ? `text-${iconColor}` : null
          )}
        />
        <span className={classNames('align-middle')}>{label}:</span>
      </dt>
      <dd className={classNames('font-weight-bold', 'd-inline')}>
        <span className={classNames('align-middle')}>{children}</span>
      </dd>
    </div>
  );
}

export { IconDefinition };
