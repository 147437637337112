import * as React from 'react';
import classNames from 'classnames';

import { DocsHeading } from '../../docs';
import { LayoutContainer } from '../LayoutContainer';

// import styles from './styles.module.scss';

const DocsLayout = ({
  inline = false,
  pageTitle = null,
  sectionTitle = null,
  leadParagraph = null,
  children,
  ...other
}) => {
  return (
    <div
      className={classNames(
        'docs-wrapper',
        inline ? 'docs-inline' : 'docs-page',
        'docs',
        'd-flex',
        'flex-column',
        'flex-fill',
        'pe-auto pr-auto',
        // 'overflow-auto', // Not needed? site layout handles overflow
        'py-3'
      )}
    >
      <div
        {...other}
        className={classNames('docs', 'd-flex', 'flex-column', 'flex-fill')}
      >
        {pageTitle && (
          <LayoutContainer>
            <DocsHeading
              sectionTitle={sectionTitle}
              className={classNames('mb-3')}
            >
              {pageTitle}
            </DocsHeading>
          </LayoutContainer>
        )}
        {leadParagraph && (
          <LayoutContainer>
            <div className={classNames('lead', 'mb-2', 'fs-4')}>
              {leadParagraph}
            </div>
          </LayoutContainer>
        )}
        {children}
      </div>
    </div>
  );
};

export { DocsLayout };
