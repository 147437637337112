import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { LayoutContainer } from '@penfold/react-components';

import { basePageWrap } from '../BasePage';
// import { RichTextField } from '../../components/RichTextField';
import { LazyBlurredImage } from '../../components/Image';
import {
  APIRichTextBlock,
  BigHeroBlock,
  HeroBlock,
  GhostHeroBlock,
  DividerBlock,
} from '../../components/blocks';

function ContentPageHeroBlock({ hero }) {
  const rendered: React.ReactNode[] = [];

  for (const block of hero) {
    // FIXME: Move error checking out of here
    switch (block?.type) {
      case 'hero': {
        rendered.push(
          <BigHeroBlock key={block.id} id={block.id} value={block.value} />
        );
        break;
      }
      case 'ghost_hero': {
        rendered.push(
          <GhostHeroBlock key={block.id} id={block.id} value={block.value} />
        );
        break;
      }
    }
  }
  return <>{rendered}</>;
}

function ContentPageBodyBlock({ body }) {
  const rendered: React.ReactNode[] = [];
  let serialIdx = 0;

  if (!body) return null;

  for (const block of body) {
    // FIXME: Move error checking out of here
    if (!block) {
      console.warn('Cannot render null item');
      rendered.push(
        <div
          key={serialIdx}
          role="alert"
          className={classNames(
            'alert',
            'alert-warning',
            'text-white',
            'my-3'
          )}>
          Cannot render null item at index {serialIdx}
        </div>
      );
      continue;
    }

    switch (block?.type) {
      case 'hero': {
        rendered.push(
          <HeroBlock key={block.id} id={block.id} value={block.value} />
        );
        break;
      }
      case 'paragraph': {
        rendered.push(
          <APIRichTextBlock key={block.id} id={block.id} value={block.value} />
        );
        break;
      }
      case 'divider': {
        rendered.push(
          <DividerBlock key={block.id} id={block.id} value={block.value} />
        );
        break;
      }
      case 'image': {
        rendered.push(
          <div
            key={block.id}
            className={classNames(
              'bg-dark',
              'bg-gradient',
              'mt-2',
              'mb-4',
              'py-4'
            )}>
            <LayoutContainer>
              <LazyBlurredImage {...block.value} />
            </LayoutContainer>
          </div>
        );
        break;
      }
    }

    serialIdx++;
  }

  return <>{rendered}</>;
}

const ContentPage = ({ title, bigHero, body }) => {
  return (
    <div className={classNames('mb-5')}>
      <ContentPageHeroBlock hero={bigHero} />
      <ContentPageBodyBlock body={body} />
    </div>
  );
};

ContentPage.defaultProps = {
  bigHero: [],
  body: '',
};

// ContentPage.propTypes = {
//   title: PropTypes.string.isRequired,
//   bigHero: PropTypes.list(PropTypes.object),
//   body: PropTypes.string,
// };

export default basePageWrap(ContentPage);
