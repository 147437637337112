import React from 'react';
import classNames from 'classnames';

import { LayoutContainer } from '@penfold/react-components';

import { RichTextField } from '../../components/RichTextField';

function APIRichTextBlock({ id, value }) {
  return (
    <LayoutContainer>
      <div className={classNames('rich-text')}>
        <RichTextField richText={value} />
      </div>
    </LayoutContainer>
  );
}

export { APIRichTextBlock };
