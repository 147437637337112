import { initPlasmicLoader } from '@plasmicapp/loader-nextjs';

// FIXME: This list of projects should exist externally to this file so that it
// can be code-generated and/or queried at build- and/or run-times
const projects = [
  // plasmic-home
  {
    id: 'hZH6dJZ28A8UKtmvr73k5y',
    token:
      'GhF8bKWxShJeuosijda05p8MsR4KyZq2SPqS5zIG3DUkdtVrDNgmpgpc32jlp5DzGkWPQs34Aj2eFjooRT8Cw', // API token for that project
  },
  // plasmic-demo
  {
    id: 'aKitDDRxXUeT3qwsgHmRX3',
    token:
      'fo1dLBFcidncWCyJT4qjpBXPzTgDDPpfeRICgrhugwgalOU5Z0plPsqJ6cOovJbv4cqXQgWiUhZzJnfVDoA', // API token for that project
  },
];

const PLASMIC = initPlasmicLoader({
  projects: projects,

  // Fetches the latest revisions, whether or not they were unpublished!
  // Disable for production to ensure you render only published changes.
  preview: false,
});

export { PLASMIC };
