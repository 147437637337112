// Required for tree-shaking using `getMaterialIconPath` approach.
import {
  mdiPuzzle,
  mdiHome,
  mdiCalendar,
  mdiClock,
  mdiHumanMaleBoard,
  mdiBarcodeScan,
  mdiPlus,
  mdiChevronDown,
  mdiDotsVertical,
  mdiCheck,
  mdiPowerStandby,
  mdiCheckboxMarkedOutline,
  mdiMessageAlert,
  mdiMessageAlertOutline,
  mdiCheckCircle,
  mdiAlert,
  mdiAlertOutline,
  mdiAlertCircle,
  mdiAlertOctagon,
  mdiInformation,
  mdiInformationOutline,
  mdiWeatherSunny,
  mdiWeatherNight,
  mdiPhone,
  mdiBell,
  mdiAccount,
  mdiAccountTie,
  mdiArrowLeft,
  mdiArrowRight,
  mdiUnfoldMoreHorizontal,
  mdiAutorenew,
  mdiMagnify,
  mdiTuneVariant,
  mdiMapMarkerPath,
  mdiTableOfContents,
  mdiFileMultiple,
  mdiFolderOutline,
  mdiFolder,
  mdiCube,
  mdiBriefcase,
  mdiFileDocument,
  mdiFormatListChecks,
  mdiMore,
  mdiStar,
  mdiClipboardPlay,
  mdiClipboardList,
  mdiClipboard,
  mdiPlayBox,
  mdiPlayBoxMultiple,
  mdiImage,
  mdiMathCompass,
  mdiSitemap,
  mdiPalette,
  mdiHammerWrench,
} from '@mdi/js';
// TODO: add missing:
// status update icons (StatusUpdate.tsx)
// Server-side definitions (summary)

export default {
  mdiPuzzle,
  mdiHome,
  mdiCalendar,
  mdiClock,
  mdiHumanMaleBoard,
  mdiBarcodeScan,
  mdiPlus,
  mdiChevronDown,
  mdiDotsVertical,
  mdiCheck,
  mdiPowerStandby,
  mdiCheckboxMarkedOutline,
  mdiMessageAlert,
  mdiMessageAlertOutline,
  mdiCheckCircle,
  mdiAlert,
  mdiAlertOutline,
  mdiAlertCircle,
  mdiAlertOctagon,
  mdiInformation,
  mdiInformationOutline,
  mdiWeatherSunny,
  mdiWeatherNight,
  mdiPhone,
  mdiBell,
  mdiAccount,
  mdiAccountTie,
  mdiArrowLeft,
  mdiArrowRight,
  mdiUnfoldMoreHorizontal,
  mdiAutorenew,
  mdiMagnify,
  mdiTuneVariant,
  mdiMapMarkerPath,
  mdiTableOfContents,
  mdiFileMultiple,
  mdiFolderOutline,
  mdiFolder,
  mdiCube,
  mdiBriefcase,
  mdiFileDocument,
  mdiFormatListChecks,
  mdiMore,
  mdiStar,
  mdiClipboardPlay,
  mdiClipboardList,
  mdiClipboard,
  mdiPlayBox,
  mdiPlayBoxMultiple,
  mdiImage,
  mdiMathCompass,
  mdiSitemap,
  mdiPalette,
  mdiHammerWrench,
};
