import React from 'react';
import Head from 'next/head';
// import Link from 'next/link';
import PropTypes from 'prop-types';
// import dynamic from 'next/dynamic';

// const WagtailUserbar = dynamic(() => import('../../components/WagtailUserbar'));

const BasePage = ({ children, seo, site, wagtailUserbar }) => {
  const {
    seoHtmlTitle,
    seoMetaDescription,
    seoOgTitle,
    seoOgDescription,
    seoOgUrl,
    seoOgImage,
    seoOgType,
    seoTwitterTitle,
    seoTwitterDescription,
    seoTwitterUrl,
    seoTwitterImage,
    seoMetaRobots,
    canonicalLink,
  } = seo;
  const { hostname, port, siteName, rootPageId, rootUrl, isDefault } = site;

  return (
    <>
      <Head>
        <title>{seoHtmlTitle}</title>
        <link rel="icon" href="/favicon.ico" />
        {!!seoMetaDescription && (
          <meta name="description" content={seoMetaDescription} />
        )}
        {!!seoOgTitle && <meta property="og:title" content={seoOgTitle} />}
        {!!seoOgDescription && (
          <meta property="og:description" content={seoOgDescription} />
        )}
        {!!seoOgUrl && <meta property="og:url" content={seoOgUrl} />}
        {!!seoOgImage && <meta property="og:image" content={seoOgImage} />}
        {!!seoOgType && <meta property="og:type" content={seoOgType} />}
        {!!seoTwitterTitle && (
          <meta property="twitter:title" content={seoTwitterTitle} />
        )}
        {!!seoTwitterDescription && (
          <meta
            property="twitter:description"
            content={seoTwitterDescription}
          />
        )}
        {!!seoTwitterUrl && (
          <meta property="twitter:url" content={seoTwitterUrl} />
        )}
        {!!seoTwitterImage && (
          <meta property="twitter:image" content={seoTwitterImage} />
        )}
        <meta name="robots" content={seoMetaRobots} />
        {!!canonicalLink && <link rel="canonical" href={canonicalLink} />}
        {/* <link
                  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
                  rel="stylesheet"
                  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
                  crossOrigin="anonymous" />
                <script
                      src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
                      integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
                      crossOrigin="anonymous"></script> */}
      </Head>
      {children}
      {/* Disable for now as it interferes with page display */}
      {/* {!!wagtailUserbar && <WagtailUserbar {...wagtailUserbar} />} */}
    </>
  );
};

BasePage.defaultProps = {
  seo: {},
};

BasePage.propTypes = {
  children: PropTypes.node,
  seo: PropTypes.shape({
    seoHtmlTitle: PropTypes.string,
    seoMetaDescription: PropTypes.string,
    seoOgTitle: PropTypes.string,
    seoOgDescription: PropTypes.string,
    seoOgUrl: PropTypes.string,
    seoTwitterTitle: PropTypes.string,
    seoMetaRobots: PropTypes.string,
  }),
  wagtailUserbar: PropTypes.shape({
    html: PropTypes.string,
  }),
};

export default BasePage;
