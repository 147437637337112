import {
  PlasmicRootProvider,
  PlasmicComponent,
  ComponentRenderData,
} from '@plasmicapp/loader-nextjs';
import { PLASMIC } from '../../plasmic-init';

// Render the page or component from Plasmic.
function PlasmicPage(props: { plasmicData: ComponentRenderData }) {
  return (
    <PlasmicRootProvider loader={PLASMIC} prefetchedData={props.plasmicData}>
      <PlasmicComponent component={props.path} />
    </PlasmicRootProvider>
  );
}

export default PlasmicPage;
