import React from 'react';
import Link from 'next/link';
import htmlReactParser, { domToReact } from 'html-react-parser';
import classNames from 'classnames';

import { LazyBlurredImage } from '../Image';

const RichTextField = ({ richText }) => {
  const parserOptions = {
    replace: (domNode) => {
      if (!domNode.attribs) {
        return;
      }

      let returnNode;

      switch (domNode.name) {
        // Handle anchors
        case 'a':
          let LinkTag = 'a';
          // TODO: prop-based checking for local vs. remote link
          // Requires support on the serializer side (wagtail)
          if (Object.keys(domNode.attribs).includes('href')) {
            LinkTag = Link;
          }

          returnNode = (
            <LinkTag
              className={classNames('text-decoration-underline')}
              {...domNode.attribs}>
              {domToReact(domNode.children, parserOptions)}
            </LinkTag>
          );
          break;

        // Handle images
        case 'img':
          // NOTE: Keep sync with `customimage.image_formats.CustomImageFormat`
          // https://nextjs.org/docs/api-reference/next/image
          const imageProps = block.attribs;
          const meta = {
            blurhash: imageProps['data-blurhash'],
            imageId: parseInt(imageProps['data-image-id'], 10),
            imageGuid: imageProps['data-image-guid'],
            filterSpec: imageProps['data-filter-spec'],
            filterCacheKey: imageProps['data-filter-cache-key'],
            filePrefix: imageProps['data-file-prefix'],
            fileExtension: imageProps['data-file-extension'],
          };

          delete imageProps['data-blurhash'];
          delete imageProps['data-image-id'];
          delete imageProps['data-image-guid'];
          delete imageProps['data-filter-spec'];
          delete imageProps['data-filter-cache-key'];
          delete imageProps['data-file-prefix'];
          delete imageProps['data-file-extension'];
          delete imageProps['data-meta-attrs']; // TODO: use me for above

          imageProps.key = String(index);

          // TODO: Translate classnames between left|right|full-width
          if (imageProps.class) {
            imageProps.className = imageProps.class;
            delete imageProps.class;
          }

          // TODO: implement layout from metadata?
          // imageProps.layout = 'responsive';
          // const imageProps.layout = imageProps['data-layout'];
          // delete imageProps['data-layout'];

          // console.log('RichTextField.imageProps', imageProps);
          // console.log('RichTextField.meta', meta);

          processedBlocks.push(
            <LazyBlurredImage meta={meta} {...imageProps} />
          );
          break;

        case 'embed':
          // TODO
          // switch (block.attribs.embedtype) {
          //   case 'image':
          //     processedBlocks.push(
          //       <ImageFluid
          //         key={index}
          //         alt={block.attribs.alt}
          //         format={block.attribs.format}
          //         imageId={block.attribs.id}
          //       />
          //     );
          //     break;
          //   default:
          //     console.error('FIXME: Unhandled embed type', block.attribs.embedtype);
          //     processedBlocks.push(
          //       <mark key={index}>
          //         Unhandled embed type{' '}
          //         <code className={classNames('text-reset')}>
          //           {block.attribs.embedtype}
          //         </code>
          //       </mark>
          //     );
          //     break;
          // }
          break;
      }

      return returnNode;
    },
  };
  const parsedRichText = htmlReactParser(richText, parserOptions);

  return parsedRichText;
};

export { RichTextField };
