import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'react-bootstrap5/Button';
import Col from 'react-bootstrap5/Col';
import Row from 'react-bootstrap5/Row';
import Badge from 'react-bootstrap5/Badge';
import Form from 'react-bootstrap5/Form';

import { BlockQuote, LayoutContainer, Icon } from '@penfold/react-components';

import { basePageWrap } from '../BasePage';
import { RichTextField } from '../../components/RichTextField/RichTextField';
import { FeatureImage } from '../../components/Image';
import { IconDefinition } from '../../components/IconDefinition';
import { ResponsivePageSection } from '../../components/ResponsivePageSection';

function ProductPage({ course, title }) {
  const learningMethod =
    course.learningMethod === 'instructor_led'
      ? 'Instructor-led'
      : 'Self-paced';

  // debugger;
  // const featureImageBlurhash = course.featureImage['data-blurhash'];
  // delete imageProps['data-blurhash'];

  const courseTypeBadge = useMemo(() => {
    const isAdv = course.courseType === 'advanced';
    const bgColor = isAdv ? 'success' : 'info';
    const badgeText = isAdv ? 'Advanced Career Training' : 'Fundamentals';
    const useIconName: string = isAdv ? 'AccountTie' : 'HammerWrench';

    return (
      <Badge
        bg={bgColor}
        className={classNames(
          'text-white',
          'mb-3',
          'rounded-bottom',
          'rounded-0',
          'bg-gradient',
          'pt-3',
          'pb-2',
          'px-3',
          'shadow-lg'
        )}
        style={{ fontSize: '1.05rem' }}>
        <Icon
          name={useIconName}
          size={0.85}
          className={classNames('me-2', 'mt-n1')}
        />
        {badgeText}
      </Badge>
    );
  }, [course]);

  return (
    <>
      <div
        className={classNames(
          'bg-primaryToneDark bg-gradient text-white',
          'user-select-none'
        )}>
        <div className={classNames('container', 'col-xxl-8', 'px-4')}>
          <Row className={classNames('gx-4', 'gx-lg-5')}>
            <Col lg={4} />
            <Col lg={8}>{courseTypeBadge}</Col>
          </Row>
          <div className="row flex-lg-row align-items-top g-4 g-lg-5 py-2">
            <div className="col col-sm-8 col-lg-4">
              <div
                className={classNames(
                  'shadow-sm',
                  'rounded-2',
                  'border',
                  'border-1',
                  'border-dark'
                  // 'd-flex' // If layout="intrinsic" (default) ONLY
                )}>
                <FeatureImage
                  {...course.featureImage.imageProps}
                  // layout={'intrinsic'}
                  // layout={'fixed'}
                  // layout={'fill'}
                  layout={'responsive'}
                  sizes={['30vw']}
                  className={classNames(
                    course.featureImage.imageProps.className,
                    'rounded-2'
                  )}
                />
              </div>
            </div>
            <div className="col-lg-8">
              <h1
                className={classNames(
                  'h1',
                  'fw-bold',
                  'lh-1',
                  'mb-3',
                  'text-primaryToneLight'
                )}>
                {title}
              </h1>
              <div className="lead mb-4">
                <RichTextField richText={course.description} />
              </div>

              <hr className={classNames('bg-light', 'opacity-25')} />

              <Row>
                <Col>
                  <h2
                    className={classNames(
                      'h6',
                      'py-2',
                      'opacity-75',
                      'fw-light'
                    )}
                    style={{ fontSize: '1rem', letterSpacing: '0.1rem' }}>
                    Key details
                  </h2>
                  <dl
                    className={classNames(
                      'align-center',
                      'my-2',
                      'row',
                      'd-lg-flex'
                    )}
                    style={{ fontSize: '0.95rem' }}>
                    <IconDefinition
                      iconName="Calendar"
                      iconTitle={false}
                      iconColor={'pop4'}
                      label={'Duration'}>
                      {course.durationWeeks} weeks
                    </IconDefinition>
                    <IconDefinition
                      iconName="Clock"
                      iconTitle={false}
                      iconColor={'pop4'}
                      label={'Course Hours'}>
                      {course.durationHours}
                    </IconDefinition>
                    <IconDefinition
                      iconName="HumanMaleBoard"
                      iconTitle={false}
                      iconColor={'pop4'}
                      label={'Learning Method'}>
                      {learningMethod}
                    </IconDefinition>
                  </dl>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>

      <div
        className={classNames(
          'bg-primaryToneDarker',
          'text-white',
          'py-2',
          'mb-4',
          'user-select-none'
        )}>
        <div className="container px-4">
          <div className="row flex-lg-row align-items-top g-4 g-lg-5 py-5">
            <div className="col col-sm-8 col-lg-4">
              <h4
                className={classNames(
                  'h6',
                  'fw-light',
                  'text-uppercase',
                  'mb-1',
                  'opacity-75'
                )}
                style={{ fontSize: '1rem', letterSpacing: '0.1rem' }}>
                Offered in partnership with
              </h4>
              <div className={classNames('fs-5', 'fw-bold')}>
                Important School Name
              </div>
              <div className={classNames('mt-2')}>
                <Button
                  type={'button'}
                  size="sm"
                  variant="link"
                  className={classNames(
                    'text-primaryToneLighter',
                    'ms-n2',
                    'me-1',
                    'text-decoration-underline'
                  )}>
                  Link button
                </Button>
                <Button
                  type={'button'}
                  size="sm"
                  variant="link"
                  className={classNames(
                    'text-primaryToneLighter',
                    'mx-1',
                    'text-decoration-underline'
                  )}>
                  Alt Button
                </Button>
              </div>
            </div>

            <div className="col-lg-8">
              <h4
                className={classNames(
                  'h6',
                  'fw-light',
                  'text-uppercase',
                  'mb-1',
                  'opacity-75'
                )}
                style={{ fontSize: '1rem', letterSpacing: '0.1rem' }}>
                Learning Method
              </h4>
              <div
                style={{
                  borderStyle: 'solid',
                  borderColor:
                    'rgba(var(--bs-primaryToneLight-rgb, 128, 128, 128), 0.12)',
                  backgroundColor:
                    'rgba(var(--bs-primaryToneLight-rgb, 128, 128, 128), 0.06)',
                }}
                className={classNames('border-2', 'rounded-2', 'p-3', 'mt-2')}>
                <div
                  className={classNames('d-flex', 'flex-row', 'align-center')}>
                  <div className={classNames('me-2')}>
                    <Form.Check
                      type={'radio'}
                      defaultChecked
                      label={
                        <>
                          <h5
                            className={classNames(
                              'p-0',
                              'm-0',
                              'text-lessLight'
                            )}>
                            {learningMethod}
                          </h5>
                          <small className={classNames('lh-1')}>
                            Proin hendrerit laoreet sem vel scelerisque
                          </small>
                        </>
                      }
                      aria-label={learningMethod}
                    />
                  </div>

                  <div className={classNames('align-self-end', 'ms-auto')}>
                    <Button
                      type="button"
                      variant="success"
                      className={classNames('px-4', 'me-md-2')}>
                      Enroll Now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ResponsivePageSection label="Outline">
        <RichTextField richText={course.outline} />
      </ResponsivePageSection>

      <ResponsivePageSection label="Details">
        <RichTextField richText={course.details} />
      </ResponsivePageSection>

      <ResponsivePageSection label="More">
        <dl
          className={classNames('align-center', 'row', 'd-lg-flex', 'my-n1')}
          style={{ fontSize: '0.95rem' }}>
          <IconDefinition
            iconName="BarcodeScan"
            iconTitle={false}
            iconColor={'neutral'}
            label={'Course Code'}>
            <code>{course.courseCode}</code>
          </IconDefinition>
        </dl>
      </ResponsivePageSection>

      <div
        className={classNames(
          'bg-lessLight',
          'py-5',
          'mt-4',
          'user-select-none'
        )}>
        <LayoutContainer>
          <BlockQuote>
            Quisque nec eros eget ex finibus sollicitudin et nec turpis. Integer
            non aliquam ante. Praesent a ligula finibus, eleifend ipsum ut,
            mollis odio. Duis varius mi et magna aliquet aliquam.
            <p className={'text-end'}>
              <small>— Johnny Emdash</small>
            </p>
          </BlockQuote>
        </LayoutContainer>
      </div>
    </>
  );
}

ProductPage.defaultProps = {
  title: '',
  richText: '',
};

ProductPage.propTypes = {
  title: PropTypes.string.isRequired,
  richText: PropTypes.string,
};

// FIXME: layout does not currently work because of base page wrapper
// ProductPage.getLayout = function getLayout(page) {
//   return <div className={'bg-dark'}>{page}</div>;
// };

export default basePageWrap(ProductPage);
